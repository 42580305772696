<template>
  <v-card>
    <v-card-title class="pl-0 pr-0">
      <TableSearch
        v-model="params.term"
        label="Pesquisar por assunto..."
        @input="onInputTableMessageSearch()"
      ></TableSearch>

      <v-spacer></v-spacer>

      <v-row>
        <v-col cols="12" sm="12" class="page-actions">
          <FilterEncapsulated
            class="filter-encapsulated"
            :show-title="false"
            :show-switch="false"
            @applyFilter="filtrar(true)"
            @clearFilters="clearFilter"
          >
            <template v-slot:filters>
              <div class="filter-fields">
                <EstablishmentSelect v-model="filter.cnpjs" :companies="companies" @change="checkSelectedFilters()" />

                <AGDatePickerRange
                  id="receiving-period"
                  v-model="filter.dates"
                  label="Período de Recebimento"
                  @change="checkSelectedFilters()"
                />

                <AGDatePicker
                  id="due-date"
                  v-model="filter.prazo"
                  label="Vencimento"
                  @change="checkSelectedFilters()"
                />

                <SituationSelect v-model="filter.situation" @change="checkSelectedFilters()" />

                <RelevanceSelect v-model="filter.relevancia" @change="checkSelectedFilters()" />
              </div>
            </template>
          </FilterEncapsulated>

          <AGButton id="idBtnExport" type="secondary" @click="exportar()">
            <v-icon left>mdi-download-outline</v-icon>
            Exportar
          </AGButton>
        </v-col>
      </v-row>
    </v-card-title>
    <v-row>
      <v-col cols="12" sm="12">
        <template>
          <div class="text-right" style="margin-right: 12px">
            <v-chip
              v-if="showOneChipForAllCnpjs"
              class="ma-1"
              close
              @click:close="
                lastSelecteds.cnpjs = [];
                filtrar();
              "
            >
              Todas as empresas ({{ lastSelecteds.cnpjs.length }})
            </v-chip>
            <template v-else-if="showIndividualChipsForEachCnpj">
              <v-chip
                v-for="establishment in lastSelecteds.cnpjs"
                :key="establishment.details.cnpj"
                v-model="establishment.details.isOpen"
                class="ma-1"
                close
                @click:close="removeEstablishmentFromFilter(establishment)"
              >
                {{ establishment.details.socialName }}
              </v-chip>
            </template>

            <v-chip
              v-if="filter.situation != null && showChips"
              class="ma-1"
              close
              @click:close="clickChip('situation', filter.situation)"
            >
              {{ capitalize(filter.situation) }}
            </v-chip>

            <v-chip
              v-if="filter.relevancia != null && showChips"
              class="ma-1"
              close
              @click:close="clickChip('relevance')"
            >
              {{ filter.relevancia ? 'Relevante' : 'Não Relevante' }}
            </v-chip>

            <v-chip
              v-if="filter.dates.length == 2 && showChips"
              class="ma-1"
              close
              @click:close="clickChip('received_period')"
            >
              Período: {{ concatDate(filter.dates) }}
            </v-chip>

            <v-chip v-if="filter.prazo && showChips" class="ma-1" close @click:close="clickChip('expire_date')">
              Vencimento: {{ formatDate(filter.prazo) }}
            </v-chip>
          </div>
        </template>
      </v-col>
    </v-row>
    <div id="messages-container">
      <div id="scrollDuplicate">
        <div id="dummy"></div>
      </div>
      <v-data-table
        class="pb-5"
        :loading="loading"
        :search="assuntoModelo"
        :headers="headers"
        :items="items"
        :server-items-length="totalMessages"
        :items-per-page="pagination.size"
        :footer-props="{
          'items-per-page-options': [50, 100],
        }"
        @update:page="paginate"
        @update:items-per-page="setTotalPageSize"
      >
        <template v-slot:item.relevancia="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-if="item.relevancia == 2" v-bind="attrs" v-on="on"><strong>!</strong></span>
            </template>
            <span>Relevante</span>
          </v-tooltip>
        </template>
        <template v-slot:item.establishmentName="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ start_and_end(item.establishmentName, 12) }}</span>
            </template>
            <span>{{ item.establishmentName }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | cnpj }}
        </template>
        <template v-slot:item.dataEnvio="{ item }">
          {{ item.dataEnvio | date }}
        </template>
        <template v-slot:item.dataValidade="{ item }">
          {{ item.dataValidade | date }}
        </template>
        <template v-slot:item.read="{ item }">
          <span v-if="item.readImport">Lido</span>
          <span v-else>Não Lido</span>
        </template>
        <template v-slot:item.urgent="{ item }">
          <span v-if="item.urgent">Sim</span>
          <span v-else>Não</span>
        </template>
        <template v-slot:item.situation="{ item }">
          {{ capitalize(item.situation) }}
        </template>
        <template v-slot:item.assuntoModelo="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ start_and_end(item.assuntoModelo, 29) }}</span>
            </template>
            <span>{{ item.assuntoModelo }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" @click="readConfirm(item)" v-on="on">
                <v-icon v-if="!item.read" id="idBtnEmail" small color="success">mdi-email</v-icon>
                <v-icon v-else id="idBtnEmail" small color="success">mdi-email-open</v-icon>
              </v-btn>
            </template>
            <span v-if="!item.read">Não lido</span>
            <span v-else>Lido</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" id="idBtnUpdate" icon @click="historic(item)" v-on="on">
                <v-icon id="idBtnUpdate" small color="success">mdi-update</v-icon>
              </v-btn>
            </template>
            <span>Histórico</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" id="idBtnComment" icon @click.stop="comments(item)" v-on="on">
                <v-icon v-if="item.totalComments <= 0" id="idBtnComment" color="primary" small
                  >mdi-comment-outline
                </v-icon>

                <v-badge v-else overlap dot>
                  <v-icon id="idBtnComment" color="#00FF99" small>mdi-comment</v-icon></v-badge
                >
              </v-btn>
            </template>
            <span>Comentários</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" id="idBtnEdit" icon @click="condition(item)" v-on="on">
                <v-icon small color="success">mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <ModalReadMessage ref="modalReadMessage" :item="itemReadConfirm" @refresh="refreshTable" @update="update" />
    <ModalMessageFull ref="modalMessageFull" :message="itemReadConfirm" :details="details" />
    <ModalConditionMessage ref="modalConditionMessage" :message="itemCondition" @refresh="refreshTable" />
    <ModalHistoryMessage ref="modalHistoryMessage" :history="arrHistoric" />
    <ModalCommentMessage
      ref="modalCommentMessage"
      :comentarios="arrComment"
      :msg-info-comment="msgInfo"
      @toggleIconBool="toggleIconBool"
      @refresh="refreshTable"
    />
  </v-card>
</template>
<script>
import AdminServices from '@/services/adminServices.js';
import gedService from '@/services/gedService.js';
import IntegraContadorService from '@/services/integraContadorService.js';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';

import { eventBus } from '../../../../main';

export default {
  name: 'TableMessage',

  components: {
    ModalReadMessage: () => import('./ModalReadMessage.vue'),
    ModalMessageFull: () => import('./ModalMessageFull.vue'),
    ModalConditionMessage: () => import('./ModalConditionMessage.vue'),
    ModalCommentMessage: () => import('./ModalCommentMessage.vue'),
    ModalHistoryMessage: () => import('./ModalHistoryMessage.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
    EstablishmentSelect: () => import('./EstablishmentSelect.vue'),
    SituationSelect: () => import('./SituationSelect.vue'),
    RelevanceSelect: () => import('./RelevanceSelect.vue'),
    AGDatePicker: () => import('../../commons/AGDatePicker.vue'),
    AGDatePickerRange: () => import('../../commons/AGDatePickerRange.vue'),
    AGButton: () => import('../../commons/AGButton.vue'),
    FilterEncapsulated: () => import('../../commons/FilterEncapsulated.vue'),
  },

  props: {
    read: Boolean,
    urgent: Boolean,
  },

  data() {
    return {
      closeOnClick: false,
      closeFilterAutomatically: false,
      loading: false,
      ready: false,
      details: null,
      items: [],
      totalMessages: 0,
      assuntoModelo: '',
      itemReadConfirm: null,
      itemCondition: null,
      arrComment: null,
      isActiveOrNot: false,
      arrHistoric: null,
      msgInfo: {},
      formatedReceivePeriodDates: '',
      totalSelectedFilters: 0,
      formatedSituation: '',
      receiptDateLabel: '',
      showChips: false,
      filterEmpty: true,
      filter: {
        cnpjs: [],
        dates: [],
        prazo: '',
        situation: null,
        relevancia: null,
      },

      lastSelecteds: {},
      enableCounter: false,
      params: {
        cnpjs: [],
        receiptDateFrom: null,
        receiptDateTo: null,
        daysToExpire: null,
        situation: null,
        relevant: null,
        read: this.read,
        urgent: this.urgent,
      },

      companies: [],
      headers: [
        { text: '!', value: 'relevancia', width: ' 37px', sortable: false, align: 'start' },
        { text: 'Empresa', value: 'establishmentName', width: '172px', sortable: false },
        { text: 'CNPJ', value: 'cnpj', width: '184px', sortable: false },
        { text: 'Recebimento', value: 'dataEnvio', width: '123px', sortable: false },
        { text: 'Vencimento', value: 'dataValidade', width: '134px', sortable: false },
        { text: 'Assunto', value: 'assuntoModelo', width: '284px', sortable: false },
        { text: 'Importação', value: 'read', width: '106px', sortable: false },
        { text: 'Urgente', value: 'urgent', width: ' 94px', sortable: false },
        { text: 'Situação', value: 'situation', width: '143px', sortable: false },
        { text: 'Ações', value: 'actions', width: '176px', sortable: false },
      ],

      openMenu: false,
      toggleIcon: false,
      firstPage: true,
      pagination: {
        page: 0,
        size: 50,
      },
    };
  },

  computed: {
    areAllCnpjsSelected() {
      return this.lastSelecteds.cnpjs.length === this.companies.length;
    },

    showOneChipForAllCnpjs() {
      return this.showChips && this.areAllCnpjsSelected;
    },

    showIndividualChipsForEachCnpj() {
      return this.showChips && !this.areAllCnpjsSelected;
    },

    computeReceivePeriodDates: {
      get() {
        try {
          this.receiptDateLabel = '';
          if (this.filter.dates.length == 1) {
            this.receiptDateLabel = 'Preencha a data final';
            this.formatedReceivePeriodDates = this.formatDate(this.filter.dates[0]) + ' à ';
            document.querySelector('#date-hint-div').classList.add('v-messages__message');
          } else if (this.filter.dates.length > 0) {
            this.params.receiptDateFrom = this.getMillisecondsDate(`${this.filter.dates[0]} 00:00`);
            this.params.receiptDateTo = this.getMillisecondsDate(`${this.filter.dates[1]} 23:59`);
            if (this.params.receiptDateFrom > this.params.receiptDateTo) {
              this.receiptDateLabel = 'Preencha primeiro a data mais antiga';
              document.querySelector('#date-hint-div').classList.add('v-messages__message');
            } else {
              this.formatedReceivePeriodDates =
                this.formatDate(this.filter.dates[0]) + ' à ' + this.formatDate(this.filter.dates[1]);
              this.checkSelectedFilters();
              if (document.querySelector('#date-hint-div'))
                document.querySelector('#date-hint-div').classList.remove('v-messages__message');
            }
          }
        } catch (e) {
          console.error('Erro ao carregar os parametros do filtro', e);
        }
        return this.formatedReceivePeriodDates;
      },
    },

    computePrazo: {
      get() {
        return this.formatDate(this.filter.prazo);
      },
    },

    initialCompanies: {
      get() {
        return this.companies[0];
      },
    },

    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },

  created() {
    this.init();
    this.getCompanies();
    this.filtrar();
  },

  beforeMount() {
    this.prepareListeners();
  },

  updated() {
    this.customize();
  },

  mounted() {
    this.updateScrollers();
    window.addEventListener('resize', this.updateScrollers);

    const linkScroller = (a, b) => {
      a.addEventListener('scroll', (e) => {
        b.scrollLeft = e.target.scrollLeft;
      });
    };
    const _a = this.$el.querySelector('#scrollDuplicate');
    const _b = this.$el.querySelector('.v-data-table__wrapper');
    linkScroller(_a, _b);
    linkScroller(_b, _a);
  },

  methods: {
    onInputTableMessageSearch() {
      this.filtrar(true);
    },

    clickButtonFilter() {
      this.lastSelecteds = cloneDeep(this.filter);
      this.showChips = false;
      this.openMenu = true;
      this.isActiveOrNot = true;
    },

    init() {
      this.$on('update', this.update);
      this.$on('toggleIconBool', this.toggleIconBool);
      this.$on('refreshTable', this.refreshTable);
    },

    update() {
      this.filtrar(true);
    },

    clearFilter() {
      this.lastSelecteds.cnpjs = [];
      this.filtrar(true);
    },

    toggleIconBool(value) {
      this.toggleIcon = value;
    },

    async clickChip(chipName) {
      switch (chipName) {
        case 'situation':
          this.filter.situation = null;
          break;

        case 'relevance':
          this.filter.relevancia = null;
          break;

        case 'received_period':
          this.filter.dates = [];
          this.params.receiptDateFrom = this.params.receiptDateTo = null;
          this.formatedReceivePeriodDates = '';
          break;

        case 'expire_date':
          this.filter.prazo = null;
          break;

        default:
          break;
      }
      await this.filtrar(true);
    },

    // this method is necessary because the SelectMultiple component
    // used in the EstablishmentSelect component also returns an item
    // for each of the groups that are selected, not just the group items
    removeEstablishmentFromFilter(establishmentToRemove) {
      let updatedEstablishments = [];

      // get a list from the filter that have the same groupId
      // of the establishment that will be removed
      let establishmentGroup = this.lastSelecteds.cnpjs.filter(
        (establishment) => establishment.groupId === establishmentToRemove.groupId,
      );

      // get the items that represents the individual cnpjs
      let establishmentItems = establishmentGroup.filter((establishment) => establishment.details !== undefined);

      // verify if the establishment that the user wants to remove
      // is present in the filter items
      let theGroupContainsTheDesiredEstablishment = establishmentItems.some(
        (establishment) => establishment.details.cnpj === establishmentToRemove.details.cnpj,
      );

      // verify if the group has only one item
      let theGroupHasOnlyOneItem = establishmentItems.length === 1;

      // if the group has only the item that will be removed, the group
      // item must be removed as well
      let theGroupItemMustBeRemoved = theGroupContainsTheDesiredEstablishment && theGroupHasOnlyOneItem;

      // remove the desired establishment from the list
      updatedEstablishments = this.lastSelecteds.cnpjs.filter(
        (establishment) =>
          establishment.details !== undefined && establishment.details.cnpj === establishmentToRemove.details.cnpj,
      );

      // remove the group item if necessary
      if (theGroupItemMustBeRemoved) {
        updatedEstablishments = this.lastSelecteds.cnpjs.filter(
          (establishment) =>
            establishment.details == undefined && establishment.groupId === establishmentToRemove.groupId,
        );
      }

      // update the filter with the correct values
      this.filter.cnpjs = cloneDeep(updatedEstablishments);

      // call the filter method
      this.filtrar(true);
    },

    clickChipCompany(cnpjToRemove) {
      let companies = [];

      this.filter.cnpjs.forEach((company) => {
        if (company.details.cnpj != cnpjToRemove) {
          companies.push(company);
        }
      });

      this.filter.cnpjs = companies;
      this.params.cnpjs = [];

      this.filtrar(true);

      if (this.totalSelectedFilters > 1) {
        this.enableCounter = false;
      }
    },

    concatDate(arrayDate) {
      return [this.formatDate(arrayDate[0]), this.formatDate(arrayDate[1])].join(' à ');
    },

    paginate(currentPage) {
      this.pagination.page = currentPage - 1;
      this.filtrar(true);
    },

    setTotalPageSize(totalPageSize) {
      this.pagination.size = totalPageSize;
      this.filtrar(true);
    },

    closeFilter() {
      this.isActiveOrNot = !this.isActiveOrNot;
      this.openMenu = false;
      this.showChips = true;
      this.filter = this.lastSelecteds;
      this.checkSelectedFilters();
    },

    async filtrar(closeFilter) {
      let filter = cloneDeep(this.filter);

      // filter out group items
      let filteredEstablishments = filter.cnpjs.filter((establishment) => establishment.details !== undefined);

      // store filters
      this.lastSelecteds = cloneDeep({
        cnpjs: filteredEstablishments,
        dates: filter.dates,
        prazo: filter.prazo,
        relevancia: filter.relevancia,
        situation: filter.situation,
      });

      // get only the cnpj value from establishments
      let desiredCnpjs = filteredEstablishments.map((establishment) => establishment.details.cnpj);

      this.loading = true;

      this.checkSelectedFilters();

      if (!this.filterEmpty) {
        this.showChips = true;
      }

      this.openMenu = false;
      this.isActiveOrNot = false;
      this.items = [];

      // update params for request
      this.params.cnpjs = desiredCnpjs;
      this.params.situation = filter.situation;
      this.params.relevant = filter.relevancia;

      // fetch data
      let response = await IntegraContadorService.messages.getAllFilter(this.pagination, this.params);

      this.enableCounter = closeFilter;
      this.items = response.data.content;
      this.totalMessages = response.data.totalElements;

      this.$emit('refresh', this.totalMessages);
      this.loading = false;
    },

    async getCompanies() {
      const response = await AdminServices.userEstablishment.get(this.userInfo.id);
      this.companies = response.data;

      if (this.companies.length === 0) {
        console.warn('Nenhuma empresa encontrada.');
      }
    },

    async readConfirm(item) {
      this.itemReadConfirm = item;
      if (item.read) {
        const { data } = await IntegraContadorService.messages.getDetails(item);
        this.details = data;
        this.$refs.modalMessageFull.$emit('open');
      } else {
        this.$refs.modalReadMessage.$emit('open');
      }
    },

    condition(item) {
      this.itemCondition = item;
      this.$refs.modalConditionMessage.$emit('edit');
    },

    async comments(item) {
      this.toggleIconBool(true);
      try {
        let params = 'page=0&size=50';
        const { data } = await IntegraContadorService.comment.get(item, params);
        this.msgInfo.isn = item.isn;
        this.msgInfo.cnpj = item.cnpj;

        this.arrComment = data.content.reverse();

        for (let i = 0; i < this.arrComment.length; i++) {
          this.arrComment[i].thumbnail = await this.getThumbnail(this.arrComment[i].thumbnail);
        }

        this.$refs.modalCommentMessage.$emit('open');
      } catch (error) {
        console.log('Erro ao buscar os comentários da mensagem: ', error);
      }
    },

    async getThumbnail(id) {
      let thumbnail = '';
      if (id != null) {
        let response = await gedService.profile.get(id);
        thumbnail = `data:image/png;base64,${response.data.base64}`;
        return JSON.parse(JSON.stringify(thumbnail));
      } else {
        return (thumbnail = require('@/assets/avatar.png'));
      }
    },

    closeOnParent() {
      this.$refs.modalCommentMessage.$emit('close');
    },

    async historic(item) {
      try {
        const { data } = await IntegraContadorService.historic.get(item);
        this.arrHistoric = data.content.reverse();
        this.$refs.modalHistoryMessage.$emit('open');
      } catch (error) {
        console.log('Erro ao buscar histórico da mensagem: ', error);
      }
    },

    getMillisecondsDate(dateString) {
      var date = new Date(dateString);
      return date.getTime();
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    async exportar() {
      let nothingToExport = this.items.length == 0;
      if (nothingToExport) return;

      let pageable = {
        page: 0,
        size: 0x7fffffff,
      };
      let response = await IntegraContadorService.messages.downloadReport(this.params, pageable);
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      link.download = `Mensagens do Ecac - Extraído em ${date.toLocaleDateString('pt-br')}.xlsx`;
      link.click();
      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },

    qtdDays(prazoString) {
      let numberDays = Math.ceil((new Date(prazoString) - new Date()) / (1000 * 3600 * 24));
      return numberDays < 0 ? null : numberDays;
    },

    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },

    capitalize(str) {
      let withoutUnderline = str.split('_').join(' ').toLowerCase();
      return withoutUnderline[0].toUpperCase() + withoutUnderline.slice(1);
    },

    refreshTable() {
      this.filtrar();
    },

    async limpar() {
      this.isActiveOrNot = !this.isActiveOrNot;
      this.enableCounter = false;
      this.showChips = false;
      this.filter = {
        cnpjs: [],
        dates: [],
        prazo: null,
        situation: null,
        relevancia: null,
      };
      this.params = {
        cnpjs: [],
        receiptDateFrom: null,
        receiptDateTo: null,
        daysToExpire: null,
        situation: null,
        relevant: null,
        read: this.read,
        urgent: this.urgent,
      };
      this.formatedReceivePeriodDates = '';
      this.formatedSituation = '';
      this.formatedRelevance = '';
      this.receiptDateLabel = '';

      if (document.querySelector('#date-hint-div'))
        document.querySelector('#date-hint-div').classList.remove('v-messages__message');

      this.$emit('refresh', this.totalMessages);
      await this.filtrar(false);
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    async prepareListeners() {
      this.$root.$on('updateTable', () => {
        this.updateTable();
      });
    },

    async updateTable() {
      //if (this.ready) {
      this.filtrar();
      //}
    },

    checkSelectedFilters() {
      this.filterEmpty = !(
        this.filter.cnpjs.length > 0 ||
        this.filter.dates.length > 1 ||
        this.filter.prazo ||
        this.filter.situation ||
        this.filter.relevancia
      );

      if (this.filter.dates.length > 1 && this.filter.dates[0] > this.filter.dates[1]) {
        this.filterEmpty = true;
      }
      this.totalSelectedFilters = 0;

      if (this.filter.cnpjs.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.dates.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.prazo) {
        this.totalSelectedFilters++;
      }
      if (this.filter.situation) {
        this.totalSelectedFilters++;
      }
      if (this.filter.relevancia) {
        this.totalSelectedFilters++;
      }
    },

    customize() {
      let tableLines = document.querySelectorAll('#messages-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },

    updateScrollers() {
      const dummy = this.$el.querySelector('#dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },
  },
};
</script>
<style scoped>
::v-deep #scrollDuplicate,
::v-deep .v-data-table__wrapper {
  overflow-x: auto !important;
}
::v-deep .v-data-table__wrapper table {
  max-height: 330px !important;
}

::v-deep #scrollDuplicate > div {
  height: 1px !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}

::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}

::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}

.icon-action {
  margin: 0 !important;
  padding: 0 !important;
}

.secundary-default {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

.secundary-default :hover {
  background: #e3ffee;
  width: 143%;
  height: 220%;
  color: var(--v-primary-base);
  border-radius: 5px;
}

::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}

.secundary-default-active {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  color: #fff !important;
  background-color: var(--v-primary-base) !important;
}

.secundary-default-active :hover {
  background: #e3ffee;
  width: 143%;
  height: 230%;
  color: var(--v-primary-base);
  border-radius: 5px;
}

::v-deep #textPicker:disabled {
  color: var(--v-primary-base);
  background-color: #e7e7fa !important;
}
::v-deep #textPicker {
  color: var(--v-primary-base);
}

::v-deep .mdi-calendar {
  color: var(--v-primary-base);
}

::v-deep .mdi-menu-down {
  display: none;
}

.arrow-down {
  color: var(--v-primary-base);
  position: fixed;
  margin-left: 80%;
}

#date-hint-div {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -7%;
}

#date-hint-div p {
  bottom: 3px;
}

#date-hint-div.v-messages__message {
  color: #962d33;
}

#messages-container >>> tr .text-start {
  border: none;
}

.ipt-search-divider {
  display: none;
}

.ipt-search-divider-hover {
  border: solid;
  position: absolute;
  width: 582px !important;
  color: #e3ffee;
  margin-top: 81px;
}

#limpar-btn :hover {
  background: #e3ffee;
  border-radius: 3px;
  width: 114%;
  height: 224%;
}

.page-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.filter-encapsulated {
  display: inline-block;
}

.filter-fields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
}
</style>
