var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pl-0 pr-0" },
        [
          _c("TableSearch", {
            attrs: { label: "Pesquisar por assunto..." },
            on: {
              input: function ($event) {
                return _vm.onInputTableMessageSearch()
              },
            },
            model: {
              value: _vm.params.term,
              callback: function ($$v) {
                _vm.$set(_vm.params, "term", $$v)
              },
              expression: "params.term",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "page-actions",
                  attrs: { cols: "12", sm: "12" },
                },
                [
                  _c("FilterEncapsulated", {
                    staticClass: "filter-encapsulated",
                    attrs: { "show-title": false, "show-switch": false },
                    on: {
                      applyFilter: function ($event) {
                        return _vm.filtrar(true)
                      },
                      clearFilters: _vm.clearFilter,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "filters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "filter-fields" },
                              [
                                _c("EstablishmentSelect", {
                                  attrs: { companies: _vm.companies },
                                  on: {
                                    change: function ($event) {
                                      return _vm.checkSelectedFilters()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.cnpjs,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "cnpjs", $$v)
                                    },
                                    expression: "filter.cnpjs",
                                  },
                                }),
                                _c("AGDatePickerRange", {
                                  attrs: {
                                    id: "receiving-period",
                                    label: "Período de Recebimento",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.checkSelectedFilters()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.dates,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "dates", $$v)
                                    },
                                    expression: "filter.dates",
                                  },
                                }),
                                _c("AGDatePicker", {
                                  attrs: {
                                    id: "due-date",
                                    label: "Vencimento",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.checkSelectedFilters()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.prazo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "prazo", $$v)
                                    },
                                    expression: "filter.prazo",
                                  },
                                }),
                                _c("SituationSelect", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.checkSelectedFilters()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.situation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "situation", $$v)
                                    },
                                    expression: "filter.situation",
                                  },
                                }),
                                _c("RelevanceSelect", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.checkSelectedFilters()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.relevancia,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "relevancia", $$v)
                                    },
                                    expression: "filter.relevancia",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "AGButton",
                    {
                      attrs: { id: "idBtnExport", type: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.exportar()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-download-outline"),
                      ]),
                      _vm._v(" Exportar "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              [
                _c(
                  "div",
                  {
                    staticClass: "text-right",
                    staticStyle: { "margin-right": "12px" },
                  },
                  [
                    _vm.showOneChipForAllCnpjs
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                _vm.lastSelecteds.cnpjs = []
                                _vm.filtrar()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Todas as empresas (" +
                                _vm._s(_vm.lastSelecteds.cnpjs.length) +
                                ") "
                            ),
                          ]
                        )
                      : _vm.showIndividualChipsForEachCnpj
                      ? _vm._l(
                          _vm.lastSelecteds.cnpjs,
                          function (establishment) {
                            return _c(
                              "v-chip",
                              {
                                key: establishment.details.cnpj,
                                staticClass: "ma-1",
                                attrs: { close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeEstablishmentFromFilter(
                                      establishment
                                    )
                                  },
                                },
                                model: {
                                  value: establishment.details.isOpen,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      establishment.details,
                                      "isOpen",
                                      $$v
                                    )
                                  },
                                  expression: "establishment.details.isOpen",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(establishment.details.socialName) +
                                    " "
                                ),
                              ]
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.filter.situation != null && _vm.showChips
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clickChip(
                                  "situation",
                                  _vm.filter.situation
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.capitalize(_vm.filter.situation)) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.filter.relevancia != null && _vm.showChips
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clickChip("relevance")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.filter.relevancia
                                    ? "Relevante"
                                    : "Não Relevante"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.filter.dates.length == 2 && _vm.showChips
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clickChip("received_period")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Período: " +
                                _vm._s(_vm.concatDate(_vm.filter.dates)) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.filter.prazo && _vm.showChips
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clickChip("expire_date")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Vencimento: " +
                                _vm._s(_vm.formatDate(_vm.filter.prazo)) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "messages-container" } },
        [
          _c("div", { attrs: { id: "scrollDuplicate" } }, [
            _c("div", { attrs: { id: "dummy" } }),
          ]),
          _c("v-data-table", {
            staticClass: "pb-5",
            attrs: {
              loading: _vm.loading,
              search: _vm.assuntoModelo,
              headers: _vm.headers,
              items: _vm.items,
              "server-items-length": _vm.totalMessages,
              "items-per-page": _vm.pagination.size,
              "footer-props": {
                "items-per-page-options": [50, 100],
              },
            },
            on: {
              "update:page": _vm.paginate,
              "update:items-per-page": _vm.setTotalPageSize,
            },
            scopedSlots: _vm._u([
              {
                key: "item.relevancia",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  item.relevancia == 2
                                    ? _c(
                                        "span",
                                        _vm._g(
                                          _vm._b({}, "span", attrs, false),
                                          on
                                        ),
                                        [_c("strong", [_vm._v("!")])]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Relevante")])]
                    ),
                  ]
                },
              },
              {
                key: "item.establishmentName",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.start_and_end(
                                            item.establishmentName,
                                            12
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(_vm._s(item.establishmentName))])]
                    ),
                  ]
                },
              },
              {
                key: "item.cnpj",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " ")]
                },
              },
              {
                key: "item.dataEnvio",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm._f("date")(item.dataEnvio)) + " "),
                  ]
                },
              },
              {
                key: "item.dataValidade",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("date")(item.dataValidade)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.read",
                fn: function ({ item }) {
                  return [
                    item.readImport
                      ? _c("span", [_vm._v("Lido")])
                      : _c("span", [_vm._v("Não Lido")]),
                  ]
                },
              },
              {
                key: "item.urgent",
                fn: function ({ item }) {
                  return [
                    item.urgent
                      ? _c("span", [_vm._v("Sim")])
                      : _c("span", [_vm._v("Não")]),
                  ]
                },
              },
              {
                key: "item.situation",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm.capitalize(item.situation)) + " "),
                  ]
                },
              },
              {
                key: "item.assuntoModelo",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.start_and_end(
                                            item.assuntoModelo,
                                            29
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(_vm._s(item.assuntoModelo))])]
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.readConfirm(item)
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      !item.read
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                id: "idBtnEmail",
                                                small: "",
                                                color: "success",
                                              },
                                            },
                                            [_vm._v("mdi-email")]
                                          )
                                        : _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                id: "idBtnEmail",
                                                small: "",
                                                color: "success",
                                              },
                                            },
                                            [_vm._v("mdi-email-open")]
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        !item.read
                          ? _c("span", [_vm._v("Não lido")])
                          : _c("span", [_vm._v("Lido")]),
                      ]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            id: "idBtnUpdate",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.historic(item)
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            id: "idBtnUpdate",
                                            small: "",
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("mdi-update")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Histórico")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            id: "idBtnComment",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.comments(item)
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      item.totalComments <= 0
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                id: "idBtnComment",
                                                color: "primary",
                                                small: "",
                                              },
                                            },
                                            [_vm._v("mdi-comment-outline ")]
                                          )
                                        : _c(
                                            "v-badge",
                                            { attrs: { overlap: "", dot: "" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    id: "idBtnComment",
                                                    color: "#00FF99",
                                                    small: "",
                                                  },
                                                },
                                                [_vm._v("mdi-comment")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Comentários")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { id: "idBtnEdit", icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.condition(item)
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("mdi-pencil-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Editar")])]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("ModalReadMessage", {
        ref: "modalReadMessage",
        attrs: { item: _vm.itemReadConfirm },
        on: { refresh: _vm.refreshTable, update: _vm.update },
      }),
      _c("ModalMessageFull", {
        ref: "modalMessageFull",
        attrs: { message: _vm.itemReadConfirm, details: _vm.details },
      }),
      _c("ModalConditionMessage", {
        ref: "modalConditionMessage",
        attrs: { message: _vm.itemCondition },
        on: { refresh: _vm.refreshTable },
      }),
      _c("ModalHistoryMessage", {
        ref: "modalHistoryMessage",
        attrs: { history: _vm.arrHistoric },
      }),
      _c("ModalCommentMessage", {
        ref: "modalCommentMessage",
        attrs: { comentarios: _vm.arrComment, "msg-info-comment": _vm.msgInfo },
        on: { toggleIconBool: _vm.toggleIconBool, refresh: _vm.refreshTable },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }